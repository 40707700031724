/**
 * Styles for the WCM Slide Navigation
 *
 */

// Bring in a couple files from Bootstrap so we can use the clearfix and vendor mixins (for transition, etc)
@import "../../../../themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../themes/wcm_brand_base/scss/_variables";
@import "../../../../themes/wcm_brand_base/scss/_mixins";

/* =Main Nav
 ----------------------------------------------------------*/

nav.main-nav {
  display: none;
  background-color: #fff;
  font-weight: 700;
  @include breakpoint($md) {
    display: block;
    font-size: 16px;
  }
}

nav.main-nav .menu {
  display: flex;
  margin: 0;
  padding: 0;
  border-bottom: 5px solid $wcm-dark-orange;
}

nav.main-nav .menu.gap-items {
  gap: 70px;
}

nav.main-nav .menu.justify-items {
  justify-content: space-between;
}

nav.main-nav .level-1 {
  width: auto;
  @include clearfix();
  @include breakpoint($md) {
    position: relative;
    list-style: none;
    margin: 0;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    @include transition(all .25s);
  }
  > a {
    margin: 0 0 12px;
  }
}

nav.main-nav .level-1.active-trail {
  border-bottom: 5px solid $wcm-dark-orange;
  a {
    color: $wcm-med-gray;
  }
}

nav.main-nav .level-1.home {
  border-bottom: 5px solid transparent;
}


nav.main-nav .level-1.home a {
  display: block;
  background-image: url("/profiles/wcmc/themes/wcm_brand_base/images/home-nav.svg");
  background-size: 24px 48px;
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  font: #{0/0} a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  margin: 0 10px 12px;
}

.front nav.main-nav .level-1.home {
  border-bottom: 5px solid $wcm-dark-orange;
}

.front nav.main-nav .level-1.home a {
  background-position: 0 -24px;
}

// Compact Header Variation
.compact {
  nav.main-nav .level-1.home {
    display: none;
  }
  nav.main-nav .menu {
    border-bottom: 1px solid $wcm-border-gray;
    padding: 0 0 15px;
  }
  nav.main-nav .level-1 {
    > a {
      color: $wcm-med-gray;
    }
  }
  nav.main-nav .level-1.active-trail {
    border-bottom: none;
    a {
      color: $wcm-red;
    }
  }
}



/* =Slide-Out
----------------------------------------------------------*/

#slide-out-nav {
  min-width: 300px;
  max-width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 988;
  background: $wcm-med-gray;
  color: $wcm-white;
  transition: all 0.3s;
  padding: 0 0 100px;
  overflow-x: hidden;
  visibility: hidden;
  @include breakpoint($md) {
    padding: 0 0 0;
  }
}

#slide-out-nav.active {
  left: 0;
  visibility: visible;
}

.slide-out-nav__help {
  margin: 70px 0 0;
  padding: 5px 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 10px;
  background: $wcm-black;
  color: #909090;
}

.navbar.navbar-tray-open .slide-out-nav__help {
  margin: 148px 0 0;
}

.navbar .slide-out-nav__help {
  margin: 108px 0 0;
}

#slide-nav-overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  /* middle layer, i.e. appears below the sidebar */
  z-index: 987;
}

// Disable scroll when menu is open
body.menu-active {
  overflow: hidden;
}

/* =Slide-Out Wayfinder (for small screens)
----------------------------------------------------------*/

.wayfinder.wayfinder--sm {
  display: block;
  width: 85%;
  margin: 10px 20px 20px;
  @include breakpoint($md) {
    display: none;
  }
  button {
    width: 100%;
    border-color: $wcm-white;
  }
  .dropdown-menu {
    font-size: 14px;
  }
}

/* =Slide-Out Dropdown Menu
----------------------------------------------------------*/

#slide-nav > ul.menu {
  margin: 0;
  a {
    color: $wcm-white;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    &:focus {
      color: $wcm-white;
      outline: 1px dotted $wcm-yellow;
    }
    @include breakpoint($md) {
      &:hover {
        color: $wcm-white;
        text-decoration: none;
        border-bottom: 2px solid $wcm-yellow;
      }
    }
    &.active {
      border-bottom: 2px solid $wcm-yellow;
    }
  }
}

#slide-nav ul {
  margin: 0;
  padding: 0;
}

#slide-nav li {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #868686;
  position: relative;
  @include clearfix();
  a {
    display: inline-block;
    padding: 15px 0 15px 20px;
    width: 100%;
    line-height: 1.5;
  }
  a.has-child {
    width: 245px;
    padding-right: 15px;
    border-right: 1px solid #868686;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 55px;
    height: 53px;
    border: none;
    background: none;
    padding: 0;
    &:after {
      content: '+';
      font-size: 30px;
      line-height: 1.0;
      position: absolute;
      top: 10px;
      right: 18px;
    }
    &:focus {
      outline: 1px dotted $wcm-yellow;
    }
  }
}

#slide-nav li.open {
  border-width: 0;
  padding: 0;
  > a {
    padding: 15px 0 15px 20px;
  }
  > a.has-child  {
    padding-right: 15px;
  }
  > button {
    padding: 5px 0;
    height: 53px;
    top: 0;
    &:after {
      content: '–';
      top: 7px;
    }
  }
}

/* =Slide-Out Dropdown Menu Level Colors
----------------------------------------------------------*/

#slide-nav li.level-1 > button:hover {
  @include breakpoint($md) {
    background: $wcm-dark-gray;
  }
}

#slide-nav li.level-1.open {
  > button {
    background: $wcm-dark-gray;
    border: none;
  }
}

#slide-nav li.level-2 {
  background: $wcm-dark-gray;
  > button:hover {
    background: #333333;
  }
}

#slide-nav li.level-2.open {
  > button {
    background: #333333;
  }
}

#slide-nav li.level-3 {
  background: #333333;
  > button:hover {
    @include breakpoint($md) {
      background: #222222;
    }
  }
}

#slide-nav li.level-3.open {
  > button {
    background: #222222;
  }
}

#slide-nav li.level-4 {
  background: #222222;
}

/* =Top-Level Active-Trail Hinting
----------------------------------------------------------*/

#slide-nav li.active-trail > a {
  border-bottom: 2px solid $wcm-yellow;
}

#slide-nav li.active-trail > a + button {
  border-bottom: 10px solid $wcm-yellow;
}

#slide-nav li.active-trail.open > a {
  border-bottom: 2px solid transparent;
  @include breakpoint($md) {
    &:hover {
      border-bottom: 2px solid $wcm-yellow;
    }
  }
}

#slide-nav li.active-trail.active > a + button,
#slide-nav li.active-trail.open > a + button {
  border-bottom: 2px solid transparent;
}

.front #slide-nav li.home a,
#slide-nav li.active > a.active {
  border-bottom: 10px solid $wcm-yellow;
  padding: 11px 0 11px 20px;
  @include breakpoint($md) {
    &:hover {
      border-bottom: 10px solid $wcm-yellow;
      padding: 11px 0 11px 20px;
    }
  }
}

/* =WCM GLobal Links (for small screens)
----------------------------------------------------------*/

.wcm-global-links--sm {
  margin: 20px 0 20px 20px;
  padding: 0;
  li {
    list-style-type: none;
    margin: 0;
    padding: 0 0 10px;
  }
  a {
    color: $wcm-white;
    border-bottom: 1px dotted;
    &:hover {
      color: $wcm-white;
      border-bottom: 1px solid;
      text-decoration: none;
    }
  }
}

/* =Breadcrumb Navigation
----------------------------------------------------------*/
#bc-nav {
  position: relative;
  z-index: 987;
}

.breadcrumb-nav {
  display: none;
  @include breakpoint($md) {
    display: block;
    margin: 10px 0 30px;
    padding: 0;
    font-size: 14px;
    color: $wcm-med-gray;
  }
  > li {
    display: inline-block;
    position: relative;
    border: 1px solid $wcm-border-gray;
    border-width: 1px 1px 1px 0;
    padding: 0;
    transform: skewX(-20deg);
    z-index: 10;
    &:hover {
      background: $wcm-dark-orange;
      > span > a {
        color: $wcm-white;
      }
      button {
        color: $wcm-white;
      }
    }
    > span {
      display: inline-block;
    }
    button {
      border: none;
      background: transparent;
      padding: 5px 15px;
      &:focus {
        outline: #cf4520 auto 5px;
      }
    }
    a {
      display: inline-block;
      padding: 5px 15px;
      color: $wcm-med-gray;
      &:hover {
        color: $wcm-white;
        text-decoration: none;
      }
    }
  }
  > li:first-child {
    border-width: 1px 1px 1px 1px;
  }
  > li > * {
    transform: skewX(20deg);
  }
}

span.bc-toggle-explore {
   color: $wcm-dark-orange;
   &:after {
     content: ' +';
   }
}

span.bc-toggle-siblings {
  &:after {
    content: '\e80f';
    color: $wcm-dark-orange;
    @include fontello();
    display: inline-block;
    padding-left: 10px;
  }
}

.breadcrumb-nav li:hover {
  span.bc-toggle-explore {
    color: $wcm-white;
    &:after {
      color: $wcm-white;
    }
  }
  span.bc-toggle-siblings:after {
    color: $wcm-white;
  }
}

.breadcrumb-nav li.bc-no-link {
  &:hover {
    background: transparent;
  }
  > span {
    padding: 5px 15px;
  }
}

.breadcrumb-nav .open {
  background: $wcm-dark-orange;
  color: $wcm-white;
  z-index: 11;
  span.bc-toggle-explore {
    color: $wcm-white;
    &:after {
      content: ' –';
      color: $wcm-white;
    }
  }
  span.bc-toggle-siblings:after {
    content: '\e80b';
    color: $wcm-white;
  }
  .dropdown-menu {
    left: 20px;
    // Fix breadcrumb off screen // WEBENG-2437
    max-height: 500px;
    overflow: auto;
  }
  .dropdown-menu li {
    font-size: 14px;
    padding: 2px 0;
    border-top: 1px solid $wcm-border-gray;
    &:first-child {
      border: none;
    }
    .active-trail {
      color: $wcm-dark-orange;
      font-weight: 700;
    }
  }
  .dropdown-menu >.active >a,
  .dropdown-menu >.active >a:hover,
  .dropdown-menu >.active >a:focus {
    background: transparent;
  }
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    color: $wcm-dark-orange;
  }
}

// Compact Header Variation
.compact {
  .breadcrumb-nav {
    @include breakpoint($md) {
      margin: -1px 0 30px -6px;
    }
  }
}


/* =Navbar (z-index ordering with slide nav)
----------------------------------------------------------*/

.logged-in #navbar-administration.navbar-oriented .navbar-bar {
  z-index: 1000;
}

.logged-in #navbar-administration .navbar-tray {
  z-index: 999
}

/* =Active Second Level Nav
----------------------------------------------------------*/

#active-second-level-nav {
  background: transparent;
 }

#active-second-level-nav a {
  color: $wcm-med-gray;
  &:hover {
    color: $wcm-bright-orange;
  }
}

#active-second-level-nav .level-2:after {
  border-color: #4a5258;
}

#active-second-level-nav .active-trail > a {
  &:after {
    @include breakpoint($sm) {
      content: '\e800';
      font-size: 15px;
      padding-left: 10px;
      color: $wcm-dark-orange;
      @include fontello();
      text-decoration: none;
      display: inline-block;
    }
  }
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
}

/* =Active Third Level Nav Colors
----------------------------------------------------------*/

#active-third-level-nav {
  background: #fff;
  border-color: $wcm-border-gray;
}

#active-third-level-nav a {
  color: $wcm-med-gray;
  &:hover {
    color: $wcm-bright-orange;
  }
}

#active-third-level-nav .active {
  color: $wcm-med-gray;
  font-weight: 700;
}

#active-third-level-nav .level-3.active-trail:before {
  border-top-color: #727d84;
}

/* =Second Level Nav Buttons
----------------------------------------------------------*/

#second-level-nav .wcm-button {
  background-color: #727d84;
  transition: background-color .25s;
  &:hover {
    color: white;
    background-color: darken(#727d84, 5%);
    text-decoration: none;
  }
}
